import { Dialog } from "primereact/dialog";
import { useAccessToken } from "../../hooks/useAccessToken";
import { useQueryClient } from "react-query";
import ScheduleFormComponent from "./scheduleForm";
import { useEffect, useState } from "react";
import { ISchedule } from "../../models/scheduler/schedules";
import { useGetAllTeams } from "../../api/apiClient";
import { useGetChannelsForTeam } from "../../api/team/teamApiClient";
import { ICreateScheduleRequest } from "../../models/scheduler/createScheduleRequest";
import { getCadenceForJobType } from "../../utils/scheduleUtils";
import { useInsertSchedule, useUpdateSchedule } from "../../api/scheduler/schedulerApiClient";

interface ISchedulerModalProps {
    isVisible: boolean;
    isEdit: boolean;
    initialValue?: ISchedule;
    onModalClose: () => void;
}

const SchedulerModalComponent: React.FC<ISchedulerModalProps> = ({ isVisible, isEdit, initialValue, onModalClose }) => {
    const { token } = useAccessToken();
    const queryClient = useQueryClient();

    const [id, setId] = useState<string | undefined>(initialValue?.id);
    const [teamId, setTeamId] = useState<string | undefined>(initialValue?.team_id);
    const [channelId, setChannelId] = useState<string | undefined>(initialValue?.team_id);
    const [jobTime, setJobTime] = useState<Date | null>(null);
    const [jobType, setJobType] = useState<string | undefined>(initialValue?.job_name);
    const [jobParams, setJobParams] = useState<string | undefined>();

    const { mutateAsync: insertSchedule } = useInsertSchedule(token, queryClient);
    const { mutateAsync: updateSchedule } = useUpdateSchedule(token, id ?? "", queryClient);

    const { data: availableTeams } = useGetAllTeams(token);
    const { data: availableChannels } = useGetChannelsForTeam(token, teamId);

    const resetFormAndCloseModal = () => {
        setId(undefined);
        setChannelId(undefined);
        setTeamId(undefined);
        setJobTime(null);
        setJobType(undefined);
        setJobParams(undefined);

        onModalClose();
    };

    useEffect(() => {
        if (initialValue) {
            const selectedTeam = availableTeams?.find((team) => team.id === initialValue.team_id);

            setId(initialValue.id);
            setTeamId(selectedTeam?.id);
            setChannelId(initialValue.channel_id);
            setJobTime(new Date(initialValue.scheduled_time_utc));
            setJobType(initialValue.job_name);
            setJobParams(initialValue.job_params);
        } else {
            resetFormAndCloseModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, availableTeams]);

    const submitHandler = async () => {
        if (isEdit) {
            const request = {
                workspaceId: initialValue?.workspace_id,
                channelId: channelId,
                cadenceDefinition: getCadenceForJobType(jobType),
                scheduledTime: jobTime,
                lastExecutedDate: initialValue?.last_executed_date,
                jobName: jobType,
                jobParams: jobParams,
            };

            await updateSchedule(request);
        } else {
            const selectedTeam = availableTeams?.find((team) => team.id === teamId);

            const request: ICreateScheduleRequest = {
                teamId: selectedTeam?.id,
                workspaceId: selectedTeam?.workspaceId,
                channelId: channelId,
                cadenceDefinition: getCadenceForJobType(jobType),
                scheduledTime: jobTime,
                jobName: jobType,
                jobParams: jobParams,
            };

            await insertSchedule(request);
        }
        resetFormAndCloseModal();
    };

    return (
        <Dialog
            header={isEdit ? "Edit schdueled job" : "Schedule new job"}
            visible={isVisible}
            style={{ width: "50vw" }}
            onHide={onModalClose}
            content={null}
        >
            <ScheduleFormComponent
                isEdit={isEdit}
                teamId={teamId}
                channelId={channelId}
                jobTime={jobTime}
                jobType={jobType}
                jobParams={jobParams}
                availableTeams={availableTeams}
                availableChannels={availableChannels}
                setTeamId={setTeamId}
                setChannelId={setChannelId}
                setJobTime={setJobTime}
                setJobType={setJobType}
                setJobParams={setJobParams}
                onSubmit={submitHandler}
                resetFormAndCloseModal={resetFormAndCloseModal}
            ></ScheduleFormComponent>
        </Dialog>
    );
};

export default SchedulerModalComponent;
