import React, { createContext } from "react";
import { useAccessToken } from "../hooks/useAccessToken";
import { jwtDecode } from "jwt-decode";
import { IQOTDJwtPayload } from "../models/security/qotdJwtPayload";

export interface IPermissionsContextValues {
    userHasPermissions: (permissions: string[]) => boolean;
}

export const PermissionsContext = createContext<IPermissionsContextValues | null>(null);

export function PermissionsContextProvider(props: { value?: IPermissionsContextValues; children?: React.ReactNode }) {
    const { token } = useAccessToken();

    const userHasPermissions = (permissions: string[]): boolean => {
        if (token) {
            const decodedToken = jwtDecode<IQOTDJwtPayload>(token);

            return permissions.every((permission) => decodedToken.permissions.includes(permission));
        }

        return false;
    };
    return (
        <PermissionsContext.Provider
            value={
                props.value ?? {
                    userHasPermissions,
                }
            }
        >
            {props.children}
        </PermissionsContext.Provider>
    );
}
