export const Permissions = {
    readAllQuestions: "read:all-questions",
    writeQuestions: "write:questions",
    readAllCategories: "read:all-categories",
    readAllSurveys: "read:all-surveys",
    readAllSchedules: "read:all-schedules",
    writeSurveys: "write:surveys",
    writeSchedules: "write:schedules",
    readAllSeasons: "read:all-seasons",
    writeSeasons: "write:seasons",
};
