import { useContext } from "react";
import { IAccessTokenContextValues, AccessTokenContext } from "../context/accessTokenContextProvider";

export const useAccessToken = (): IAccessTokenContextValues => {
    const context = useContext(AccessTokenContext);

    if (!context) {
        throw new Error("useAccessToken must be used within a AccessTokenContextProvider");
    }

    return context;
};
