import { QueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { ISeason } from "../../models/season/season";
import { IToggleSeasonVisibilityRequest } from "../../models/season/toggleSeasonVisibilityRequest";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/season`;

export const useGetAllSeasons = (token: string) => {
    const apiPath = `${basePath}`;
    const queryKey = `getAllSeasons-${token}`;
    return useQuery<ISeason[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ISeason[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useToggleSeasonVisibility = (token: string, client?: QueryClient) => {
    return useMutation({
        mutationFn: (request: IToggleSeasonVisibilityRequest) => {
            const apiPath = `${basePath}/${request.seasonId}`;

            return axios.patch(apiPath, request, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        onSuccess: () => {
            if (client) {
                client.invalidateQueries(`getAllSeasons-${token}`);
            }
        },
    });
};
