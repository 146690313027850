import { IQuestion } from "../models/question";

export const SPANISH_LOCALE_ID = "es";

export const getAvailableLevels = () => [
    {
        value: 1,
        display: "Level 1",
    },
    {
        value: 2,
        display: "Level 2",
    },
    {
        value: 3,
        display: "Level 3",
    },
    {
        value: 4,
        display: "Level 4",
    },
];

export const getSpanishTranslation = (question: IQuestion): string => {
    const spanishTranslation = question.translations.find((translation) => translation.languageId.toLowerCase() === SPANISH_LOCALE_ID);

    return spanishTranslation ? spanishTranslation.content : "";
};
