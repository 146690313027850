import { Card } from "primereact/card";
import logo from "../../assets/QOTD-new-logo.svg";

const HomeComponent: React.FC = () => {
    return (
        <Card className="m-3 w-full" data-testid="home">
            <div className="flex flex-column align-items-center gap-4">
                <img src={logo} alt="QOTD logo" className="h-auto w-14rem" />
                <label>You need to log in to use the app!</label>
            </div>
        </Card>
    );
};

export default HomeComponent;
