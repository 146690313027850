import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useDeleteScheduledJob, useGetSchedules } from "../../api/apiClient";
import { useAccessToken } from "../../hooks/useAccessToken";
import { ISchedule } from "../../models/scheduler/schedules";
import { useQueryClient } from "react-query";
import { Button } from "primereact/button";
import SchedulerActionsComponent from "./schedulerActions";
import SchedulerModalComponent from "./schedulerModal";

const SchedulerDashboardComponent: React.FC = () => {
    const { token } = useAccessToken();
    const { data, isLoading } = useGetSchedules(token);
    const queryClient = useQueryClient();
    const { mutate: deleteScheduledJob } = useDeleteScheduledJob(token, queryClient);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState<ISchedule | undefined>(undefined);

    const scheduledAtTemplate = (schedule: ISchedule) => {
        return new Date(schedule.scheduled_time_utc).toLocaleTimeString("en-US", { timeZone: "UTC" });
    };

    const lastExecutionTemplate = (schedule: ISchedule) => {
        return schedule.last_executed_date ? new Date(schedule.last_executed_date).toLocaleString("en-US", { timeZone: "UTC" }) : "";
    };

    const cadenceTemplate = (schedule: ISchedule) => {
        return schedule.cadence_definition === "*" ? "Daily" : "Once";
    };

    const actionsTemplate = (schedule: ISchedule) => {
        return (
            <div className="flex flex-auto flex-row gap-1">
                <Button severity="info" icon="pi pi-pencil" rounded onClick={() => openModalToEditSchedule(schedule)}></Button>
                <Button
                    severity="info"
                    icon="pi pi-trash"
                    rounded
                    onClick={() => {
                        deleteScheduledJob(schedule.id);
                    }}
                ></Button>
            </div>
        );
    };

    const openModalToEditSchedule = (schedule: ISchedule) => {
        setSelectedSchedule(schedule);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setSelectedSchedule(undefined);
        setIsModalVisible(false);
    };

    return (
        <>
            <div className="p-0 border-round border-2 border-orange-200" data-testId="scheduled-questions-list">
                <SchedulerModalComponent
                    isVisible={isModalVisible}
                    onModalClose={closeModal}
                    isEdit={selectedSchedule !== undefined}
                    initialValue={selectedSchedule}
                ></SchedulerModalComponent>
                <SchedulerActionsComponent openModal={() => setIsModalVisible(true)}></SchedulerActionsComponent>
            </div>
            <div className="p-0 border-round border-2 border-orange-200" data-testId="scheduled-questions-list">
                <DataTable
                    value={data}
                    loading={isLoading}
                    showGridlines
                    stripedRows
                    paginator
                    scrollable
                    scrollHeight="62vh"
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: "50%" }}
                >
                    <Column field="team_id" sortable header="Team"></Column>
                    <Column field="scheduled_time_utc" sortable header="Scheduled at" body={scheduledAtTemplate}></Column>
                    <Column field="job_name" sortable header="Job type"></Column>
                    <Column field="cadence_definition" sortable header="Cadence" body={cadenceTemplate}></Column>
                    <Column field="last_executed_date" sortable header="Last execution" body={lastExecutionTemplate}></Column>
                    <Column field="" header="Actions" align={"center"} body={actionsTemplate}></Column>
                </DataTable>
            </div>
        </>
    );
};

export default SchedulerDashboardComponent;
