import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useAccessToken } from "../../hooks/useAccessToken";
import { useGetAllSeasons, useToggleSeasonVisibility } from "../../api/season/seasonApiClient";
import { InputSwitch } from "primereact/inputswitch";
import { ISeason } from "../../models/season/season";
import { useQueryClient } from "react-query";
import { usePermissions } from "../../hooks/usePermissions";
import { Permissions } from "../../constants/permissionsConstants";

const SeasonsDashboardComponent: React.FC = () => {
    const { token } = useAccessToken();
    const queryClient = useQueryClient();
    const { userHasPermissions } = usePermissions();

    const { data, isLoading } = useGetAllSeasons(token);
    const { mutate: toggleSeasonVisibility, isLoading: isLoadingVisibilityToggle } = useToggleSeasonVisibility(token, queryClient);
     
    const fromDateTemplate = (season: ISeason) => {
        return new Date(season.from_date).toLocaleDateString("en-US", { timeZone: "UTC" });
    };

    const toDateTemplate = (season: ISeason) => {
        return new Date(season.to_date).toLocaleDateString("en-US", { timeZone: "UTC" });
    };

    const actionsTemplate = (season: ISeason) => {
        const userCanWriteSeasons = userHasPermissions([Permissions.writeSeasons]);

        return <InputSwitch className={`p-0 ${isLoadingVisibilityToggle ? "cursor-wait" : ""}`}
                checked={!season.is_deleted} 
                disabled={!userCanWriteSeasons || isLoadingVisibilityToggle} 
                onChange={() => { toggleSeasonVisibility({ seasonId: season.id });}}>
            </InputSwitch>;
    };

    return (
        <>
            <div className="p-0 border-round border-2 border-orange-200" data-testId="seasons-list">
                <DataTable
                    value={data}
                    loading={isLoading}
                    showGridlines
                    stripedRows
                    paginator
                    scrollable
                    scrollHeight="62vh"
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: "50%" }}
                >
                    <Column field="name" sortable header="Name"></Column>
                    <Column field="from_date" dataType="date" sortable header="Start date" body={fromDateTemplate}></Column>
                    <Column field="to_date" dataType="date" sortable header="End date" body={toDateTemplate}></Column>
                    <Column field="" header="Enabled" align={"center"} body={actionsTemplate}></Column>
                </DataTable>
            </div>
        </>
    );
};

export default SeasonsDashboardComponent;
