import { DataTable } from "primereact/datatable";
import React from "react";
import { ISurvey } from "../../models/survey/survey";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useGetSurveyAnswers } from "../../api/apiClient";
import { useAccessToken } from "../../hooks/useAccessToken";
import { ISurveyAnswer } from "../../models/survey/surveyAnswer";

interface SurveyAnswersModalProps {
    survey: ISurvey | undefined;
    isVisible: boolean;
    onModalClose: () => void;
}

const SurveyAnswersModalComponent: React.FC<SurveyAnswersModalProps> = ({ survey, isVisible, onModalClose }) => {
    const { token } = useAccessToken();
    const { isLoading, isError, data } = useGetSurveyAnswers(token, survey?.id || "");

    const dateTemplate = (answer: ISurveyAnswer) => {
        return new Date(answer.date).toLocaleDateString();
    };

    return (
        <Dialog header={survey?.description} visible={isVisible} style={{ width: "50vw" }} onHide={onModalClose} content={null}>
            {isError ? (
                <label>Oops, something failed</label>
            ) : (
                <DataTable
                    value={data}
                    loading={isLoading}
                    showGridlines
                    stripedRows
                    paginator
                    scrollable
                    scrollHeight="40vh"
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ width: "100%" }}
                >
                    <Column field="date" sortable header="Date" body={dateTemplate}></Column>
                    <Column field="workspaceId" sortable header="Team"></Column>
                    <Column field="content" sortable header="Answer"></Column>
                </DataTable>
            )}
        </Dialog>
    );
};

export default SurveyAnswersModalComponent;
