import { useContext } from "react";
import { IPermissionsContextValues, PermissionsContext } from "../context/permissionsContextProvider";

export const usePermissions = (): IPermissionsContextValues => {
    const context = useContext(PermissionsContext);

    if (!context) {
        throw new Error("usePermissions must be used within a PermissionsContextProvider");
    }

    return context;
};
