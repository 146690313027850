import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import QuestionFormComponent from "./questionForm";
import { useGetAllQuestionCategories, useInsertQuestion, useUpdateQuestion } from "../../api/apiClient";
import { useAccessToken } from "../../hooks/useAccessToken";
import { ICreateQuestionRequest } from "../../models/createQuestionRequest";
import { useQueryClient } from "react-query";
import { IQuestion } from "../../models/question";
import { getSpanishTranslation } from "../../utils/questionUtils";
import { IQuestionCategory } from "../../models/questionCategory";
import { IUpdateQuestionRequest } from "../../models/updateQuestionRequest";
import { ISeason } from "../../models/season/season";
import { useGetAllSeasons } from "../../api/season/seasonApiClient";

interface IQuestionModalProps {
    isVisible: boolean;
    isEdit: boolean;
    initialValue?: IQuestion;
    onModalClose: () => void;
}

const QuestionModalComponent: React.FC<IQuestionModalProps> = ({ isVisible, isEdit, initialValue, onModalClose }) => {
    const { token } = useAccessToken();
    const [questionValue, setQuestionValue] = useState("");
    const [includeTranslation, setIncludeTranslation] = useState(false);
    const [questionTranslation, setQuestionTranslation] = useState("");
    const [questionLevel, setQuestionLevel] = useState<number | undefined>(undefined);
    const [questionCategory, setQuestionCategory] = useState<IQuestionCategory | undefined>(undefined);
    const [season, setSeason] = useState<ISeason | undefined>(undefined);

    const queryClient = useQueryClient();
    const { data: availableCategories } = useGetAllQuestionCategories(token);
    const { data: availableSeasons } = useGetAllSeasons(token);
    const { mutate: insertQuestion } = useInsertQuestion(token, queryClient);
    const { mutate: updateQuestion } = useUpdateQuestion(token, queryClient);

    const resetFormAndCloseModal = () => {
        setQuestionValue("");
        setIncludeTranslation(false);
        setQuestionTranslation("");
        setQuestionLevel(undefined);
        setQuestionCategory(undefined);

        onModalClose();
    };

    useEffect(() => {
        if (initialValue) {
            const selectedCategory = availableCategories?.find((category) => category.id === initialValue.category);
            const selectedSeason = availableSeasons?.find((season) => season.id === initialValue.seasonId);

            setQuestionValue(initialValue.question);
            setIncludeTranslation(initialValue.hasTranslation);
            setQuestionTranslation(getSpanishTranslation(initialValue));
            setQuestionLevel(initialValue.level);
            setQuestionCategory(selectedCategory);
            setSeason(selectedSeason);
        } else {
            resetFormAndCloseModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, availableCategories, availableSeasons]);

    const submitHandler = () => {
        if (isEdit && initialValue) {
            const request: IUpdateQuestionRequest = {
                id: initialValue?.id,
                question: questionValue,
                includeTranslation: includeTranslation,
                questionTranslation: questionTranslation,
                questionLevel: questionLevel ?? -1,
                questionCategory: questionCategory?.id ?? "",
                seasonId: season?.id,
            };

            updateQuestion(request);
        } else {
            const request: ICreateQuestionRequest = {
                question: questionValue,
                includeTranslation: includeTranslation,
                questionTranslation: questionTranslation,
                questionLevel: questionLevel ?? -1,
                questionCategory: questionCategory?.id ?? "",
                seasonId: season?.id,
            };

            insertQuestion(request);
        }

        resetFormAndCloseModal();
    };

    return (
        <Dialog
            header={isEdit ? "Edit question" : "Add new question"}
            visible={isVisible}
            style={{ width: "50vw" }}
            onHide={onModalClose}
            content={null}
        >
            <QuestionFormComponent
                isEdit={isEdit}
                questionId={initialValue?.id}
                questionValue={questionValue}
                includeTranslation={includeTranslation}
                questionTranslation={questionTranslation}
                questionLevel={questionLevel}
                questionCategory={questionCategory}
                season={season}
                availableCategories={availableCategories ?? []}
                availableSeasons={availableSeasons ?? []}
                setQuestionValue={setQuestionValue}
                setIncludeTranslation={setIncludeTranslation}
                setQuestionTranslation={setQuestionTranslation}
                setQuestionLevel={setQuestionLevel}
                setQuestionCategory={setQuestionCategory}
                setSeason={setSeason}
                resetFormAndCloseModal={resetFormAndCloseModal}
                onSubmit={submitHandler}
            ></QuestionFormComponent>
        </Dialog>
    );
};

export default QuestionModalComponent;
