import React, { createContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export interface IAccessTokenContextValues {
    token: string;
}

export const AccessTokenContext = createContext<IAccessTokenContextValues | null>(null);

export function AccessTokenContextProvider(props: { value?: IAccessTokenContextValues; children?: React.ReactNode }) {
    const [token, setToken] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        async function getToken() {
            const newToken = await getAccessTokenSilently();
            setToken(newToken);
        }

        if (!token) {
            getToken();
        }
    }, [token, setToken, getAccessTokenSilently]);

    return (
        <AccessTokenContext.Provider
            value={
                props.value ?? {
                    token,
                }
            }
        >
            {props.children}
        </AccessTokenContext.Provider>
    );
}
