import { QueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { ISchedule } from "../../models/scheduler/schedules";
import { ICreateScheduleRequest } from "../../models/scheduler/createScheduleRequest";
import { IUpdateScheduleRequest } from "../../models/scheduler/updateScheduleRequest";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/schedules`;

export const useGetSchedules = (token: string) => {
    const apiPath = `${basePath}`;
    const queryKey = `getSchedules-${token}`;
    return useQuery<ISchedule[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ISchedule[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useInsertSchedule = (token: string, client?: QueryClient) => {
    return useMutation({
        mutationFn: (request: ICreateScheduleRequest) => {
            const apiPath = `${basePath}`;

            return axios.post(apiPath, request, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        onSuccess: () => {
            if (client) {
                client.invalidateQueries(`getSchedules-${token}`);
            }
        },
    });
};

export const useUpdateSchedule = (token: string, scheduleId: string, client?: QueryClient) => {
    return useMutation({
        mutationFn: (request: IUpdateScheduleRequest) => {
            const apiPath = `${basePath}/${scheduleId}`;

            return axios.put(apiPath, request, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        onSuccess: () => {
            if (client) {
                client.invalidateQueries(`getSchedules-${token}`);
            }
        },
    });
};

export const useDeleteScheduledJob = (token: string, client?: QueryClient) => {
    return useMutation({
        mutationFn: (teamId: string) => {
            const apiPath = `${basePath}/${teamId}`;

            return axios.delete(apiPath, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        onSuccess: () => {
            if (client) {
                client.invalidateQueries(`getSchedules-${token}`);
            }
        },
    });
};
